import React, { useEffect } from "react";
import Hero from "../components/Hero2";
import Reviews from "../components/Formation/Reviews";
import Program from "../components/Formation/Program";
import Info from "../components/Formation/Info";
import Callout from "../components/Callout";
import Description from "../components/Formation/Description";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Typography from "@material-ui/core/Typography";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { Helmet } from "react-helmet";
import { GoMortarBoard } from "react-icons/go";
import {
  FaMapMarkerAlt,
  FaRegCalendarCheck,
  FaDollarSign,
} from "react-icons/fa";

import { useLocation } from "@reach/router";
import queryString from "query-string";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Seo from "../components/Seo";

const useStyle = makeStyles((theme) => ({
  section: {
    ...theme.section.main,
  },

  sectionCenter: {
    ...theme.section.center,
    alignItems: "start",
  },

  sectionWhite: {
    ...theme.section.white,
  },
  bgGrey: {
    background: theme.palette.common.offWhite,
  },

  price: {
    ...theme.icon,
    backgroundColor: "#f6bf6a",
  },
  date: {
    ...theme.icon,
    backgroundColor: "#60ba8c",
  },
  location: {
    ...theme.icon,
    backgroundColor: "#664c9a",
  },
  diploma: {
    ...theme.icon,
    backgroundColor: "#eb626f",
  },
}));

const FormationTemplate = ({ data }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography component="p" variant="h6">
          {children}
        </Typography>
      ),
      // [BLOCKS.LIST_ITEM]: (node, children) => (
      //   <Typography component="li" variant="h1">
      //     {children}
      //   </Typography>
      // ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography component="h4" variant="h5">
          {children}
        </Typography>
      ),
    },

    renderText: (text) => text.replace("!", "?"),
  };
  const location = useLocation();
  const classes = useStyle();
  const theme = useTheme();

  let aujourdhui = new Date();
  aujourdhui = Date.parse(aujourdhui);
  let date = "";
  let edition = "";
  let dateDebut = "";
  let dateDeFin = "";
  let dateDeRemise = "";
  let dateDeClotureInscription = "";

  let path = location.pathname;

  for (let i = 0; i < data.formation.info.dates.length; i++) {
    if (aujourdhui < Date.parse(data.formation.info.dates[i].dateDeDebut)) {
      date = documentToReactComponents(
        data.formation.info.dates[i].jourDeFormationTest.json,
        options
      );
      edition = data.formation.info.dates[i].edition;
      dateDebut = new Date(data.formation.info.dates[i].dateDeDebut).getTime();
      dateDeFin = new Date(data.formation.info.dates[i].dateDeFin).getTime();
      dateDeRemise = new Date(
        data.formation.info.dates[i].dateRemise
      ).getTime();
      dateDeClotureInscription = new Date(
        data.formation.info.dates[i].dateFinDinscription
      ).getTime();

      break;
    } else {
      date = "Pas de dates prévus pour le moment :(";
    }
  }

  useEffect(() => {
    if (localStorage.getItem("formation_time")) {
      let timeDiffence =
        (Date.now() - localStorage.getItem(`formation_time`)) / 1000 / 3600;
      let nombreHeureDactualisation = 0;
      if (timeDiffence > nombreHeureDactualisation) {
        localStorage.removeItem("formation_time");
        localStorage.removeItem("formation");

        localStorage.setItem("formation_time", Date.now());
        let details = {
          price: data.formation.info.price,
          discountedPrices: data.formation.info.discountedPrices,
          discount: data.formation.info.discount,
          dateDebut: dateDebut,
          dateDeFin: dateDeFin,
          dateDeRemise: dateDeRemise,
          dateDeClotureInscription: dateDeClotureInscription,
          edition: edition,

          title: data.formation.title,
          referer: path,
        };

        localStorage.setItem(`formation`, JSON.stringify(details));
      }
    } else {
      localStorage.setItem("formation_time", Date.now());
      let details = {
        price: data.formation.info.price,
        discountedPrices: data.formation.info.discountedPrices,
        discount: data.formation.info.discount,
        dateDebut: dateDebut,
        dateDeFin: dateDeFin,
        dateDeRemise: dateDeRemise,
        dateDeClotureInscription: dateDeClotureInscription,
        edition: edition,

        title: data.formation.title,
        referer: path,
      };

      localStorage.setItem(`formation`, JSON.stringify(details));
    }
  }, []);
  // console.log(data.formation);
  console.log(data.formation.image);
  return (
    <>
      <Seo
        title={data.formation.title}
        description={data.formation.description.description}
        image={data.formation.image.fixed.src}
      />
      <Layout>
        <main className="formation-page">
          <Hero
            title={data.formation.title}
            description={data.formation.smallDescription.smallDescription}
            link={`/inscription/`}
            cta={"Réservez maintenant"}
            className="formation-hero"
            backgroundColor={
              data.formation.color
                ? data.formation.color.primary
                : theme.palette.secondary.main
            }
            secondary={
              data.formation.color
                ? data.formation.color.secondary
                : theme.palette.secondary.main
            }
          />

          <section className={`${classes.section} ${classes.bgGrey}`}>
            <div className="formation-info">
              <div
                className={`${classes.sectionCenter} ${classes.sectionWhite}`}
              >
                <Callout
                  id="diploma"
                  title="Diplôme"
                  description={documentToReactComponents(
                    data.formation.info.diploma.json,
                    options
                  )}
                  icone={<GoMortarBoard className={classes.diploma} />}
                />
              </div>
              <div
                className={`${classes.sectionCenter} ${classes.sectionWhite} col-3`}
              >
                <Callout
                  id="location"
                  title="Lieux"
                  description={documentToReactComponents(
                    data.formation.info.location.json,
                    options
                  )}
                  icone={<FaMapMarkerAlt className={classes.location} />}
                />

                <Callout
                  id="date"
                  title="Dates"
                  // description={date.jourDeFormation.map((element, index) => {
                  //   return <p key={index}>{element}</p>;
                  // })}

                  description={date}
                  // description={
                  //   date.json ?
                  //   documentToReactComponents(
                  //     date.json,
                  //     options
                  //   ) : "Pas de dates prévus pour le moment :("

                  // }
                  icone={<FaRegCalendarCheck className={classes.date} />}
                />

                <Callout
                  id="price"
                  title="Tarif"
                  description={
                    data.formation.info.discount ? (
                      <>
                        <Typography
                          component="p"
                          variant="h6"
                          style={{ margin: "0px" }}
                        >
                          {data.formation.info.discountedPrices} DA
                        </Typography>
                        <Typography color="error">
                          <s>{data.formation.info.price} DA </s>
                        </Typography>
                      </>
                    ) : (
                      `${data.formation.info.price} DA`
                    )
                  }
                  icone={<FaDollarSign className={classes.price} />}
                />
              </div>
            </div>
          </section>
          <Reviews data={data.formation.reviews} />
          <Description
            title={data.formation.title}
            description={data.formation.description.description}
          />
          <Program data={data.formation.program} />
          {/* <Info
            title={data.formation.title}
            location={data.formation.info.location.json}
            price={data.formation.info.price}
            discount={data.formation.info.discount}
            discountedPrices={data.formation.info.discountedPrices}
          /> */}
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query GetSingleFormations($slug: String) {
    formation: contentfulFormation(slug: { eq: $slug }) {
      title
      slug
      smallDescription {
        smallDescription
      }
      info {
        price
        discountedPrices
        discount
        diploma {
          json
        }
        location {
          json
        }
        dates {
          dateDeDebut
          dateDeFin
          jourDeFormation
          edition
          dateRemise
          dateFinDinscription
          jourDeFormationTest {
            json
          }
        }
      }
      description {
        description
      }
      reviews {
        id
        name
        date
        content {
          json
        }
        image {
          fixed(width: 50, height: 50, quality: 100) {
            ...GatsbyContentfulFixed
          }
        }
      }

      image {
        fixed(width: 1200, height: 627, quality: 100) {
          ...GatsbyContentfulFixed
        }
      }
      color {
        primary
        secondary
      }
      program {
        id
        content {
          content
        }
        color
        title
      }
    }
  }
`;

export default FormationTemplate;
