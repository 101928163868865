import React, { useState } from "react";
import { FaFacebook } from "react-icons/fa";
import Title from "../Title";
import Image from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FaCaretSquareLeft, FaCaretSquareRight } from "react-icons/fa";
import { Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";

import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";

const spliceArr = (arr, chunk) => {
  let res = [];

  for (let i = 0; i < arr.length; i += chunk) {
    res.push(arr.slice(i, i + chunk));
  }
  return res;
};

const useStyle = makeStyles((theme) => ({
  section: {
    ...theme.section.main,
  },
  sectionCenter: {
    ...theme.section.center,
  },

  direction: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "2rem",
    padding: "2rem",

    button: {
      color: "red",
    },
  },

  review: {
    padding: "1rem 0.5rem",
    border: "1px #dddfe2 solid",
    borderRadius: "3px",
    marginBottom: "1rem",
  },

  reviewBar: {
    display: "grid",
    gridTemplateColumns: "0.5fr 3fr 0fr",
    marginBottom: "1rem",
  },
  content: {
    padding: "0 1rem",
  },
  icon: {
    margin: "10px 10px 0 0",
  },

  image: {
    borderRadius: "50%",
    margin: "auto",
  },
}));

function Reviews({ data }) {
  const classes = useStyle();
  const [step, setStep] = useState(0);

  let rev = spliceArr(data, 4);

  const handleClickAddStep = () => {
    if (step < rev.length - 1) {
      setStep(step + 1);
    }
  };

  const handleClickRemoveStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <section
      className={`${classes.section} ${classes.sectionCenter} formation-reviews`}
    >
      <Typography align="center" component="h2" variant="h4">
        Nos clients adorent!
      </Typography>
      {rev.length > 1 ? (
        <div className={classes.direction}>
          <IconButton onClick={handleClickRemoveStep} color="primary">
            <ChevronLeftOutlinedIcon size={30} />
          </IconButton>
          <IconButton onClick={handleClickAddStep} color="secondary">
            <ChevronRightOutlinedIcon size={60} />
          </IconButton>
        </div>
      ) : null}
      <div className="col-2" id="reviews">
        {rev[step].map((review) => {
          return (
            <div key={review.id} className={classes.review}>
              <div className={classes.reviewBar}>
                <Image fixed={review.image.fixed} className={classes.image} />

                <div>
                  <Typography variant="subtitle1" component="h6">
                    {review.name}
                  </Typography>
                  <Typography
                    component="span"
                    className="review-date"
                    variant="caption"
                    style={{ color: "#90949c" }}
                  >
                    {review.date}
                  </Typography>
                </div>
                <div className={classes.icon}>
                  <FaFacebook size={25} color="#1877f2" className="icons" />
                </div>
              </div>
              <div className={classes.content}>
                {documentToReactComponents(review.content.json).map(
                  (element, index) => {
                    return (
                      <Typography
                        key={index}
                        component={element.type}
                        variant="subtitle1"
                      >
                        {element.type === "p" ? element.props.children : null}
                        {element.type === "ul"
                          ? element.props.children.map((el, i) => {
                              return (
                                <Typography
                                  key={i}
                                  component="li"
                                  variant="subtitle1"
                                >
                                  {el.props.children[0].props.children}
                                </Typography>
                              );
                              {
                              }
                            })
                          : null}
                      </Typography>
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Reviews;
