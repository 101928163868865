import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

function Callout({ id, icone, title, description }) {
  // if (typeof description === "object" && description.length > 1) {
  //   description = (
  //     <Typography component={}>
  //       {description.map((element, index) => {
  //         return (
  //           <Typography component="li" variant="h6" key={index}>
  //             {element.props.children}
  //           </Typography>
  //         );
  //       })}
  //     </Typography>
  //   );
  // } else {
  //   description = (
  //     <Typography component="p" variant="h6">
  //       {description}
  //     </Typography>
  //   );
  // }

  // if (description && typeof description === "object") {
  //   for (let i = 0; i < description.length; i++) {
  //     if (description[i].props) {
  //       if (description[i].type === "ul" || description[i].type === "ol") {
  //         description = (
  //           <Typography component={description[i].type}>
  //             {description[i].props.children.map((element, index) => {
  //               return (
  //                 <Typography component="li">
  //                   {" "}
  //                   {element.props.children[0].props.children[0]}{" "}
  //                 </Typography>
  //               );
  //             })}
  //           </Typography>
  //         );
  //       } else if (description[i].type === "p") {
  //         // description = <Typography></Typography>;
  //       }
  //     }
  //   }
  // }

  // console.log(description);
  return (
    <div className="callout" id={id}>
      <i>{icone} </i>
      <Typography component="h3" variant="h4">
        {title}
      </Typography>
      {description ? description : null}
    </div>
  );
}

export default Callout;
