import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.section.main,
  },
  center: {
    ...theme.section.center,
  },
  bgGrey: {
    background: theme.palette.common.offWhite,
  },
}));

function Info({ title, location, price,discount,discountedPrices }) {
  const classes = useStyles();

  return (
    <section className={`${classes.root} ${classes.bgGrey}`}>
      <div className={`col-2 section-center`}>
        <div className="callout" id="location">
          <i></i>
          <Typography component="h2" variant="h4" gutterBottom>
            Lieux de la {title}
          </Typography>
          <Typography component="p">
            ALGER : Rte AIN ALLAH, Dely Ibrahim{" "}
            <a href="https://bit.ly/3vX4rQL">(voir plan sur google maps ) </a>
          </Typography>
          <Typography component="p">
            <strong>Note:</strong> Nos salles sont toutes climatisées et
            équipées WiFi.
          </Typography>
        </div>
        <div className="callout" id="price">
          <i></i>
          <Typography component="h2" variant="h4" gutterBottom>
            Tarif de la {title}
          </Typography>
          <ul>

          {
                  discount ? (
                    <>
                      <Typography
                        component="li"
                        variant="subtitle1"
                        style={{ margin: "0px" }}
                      >
                       Tarif Particulier: <s >{price} DA</s> -  <strong>{discountedPrices} DA </strong>  
                      </Typography>

                      <Typography
                        component="li"
                        variant="subtitle1"
                        style={{ margin: "0px" }}
                      >
                      Tarif Entreprise : <s >{price + 5000} DA</s> -  <strong>{discountedPrices + 5000} DA </strong>  
                      </Typography>
                 
                    </>
                  ) : (
                    <Typography
                    component="li"
                    variant="subtitle1"
                    style={{ margin: "0px" }}
                  >
                   Tarif Particulier: : <strong >{price} DA</strong> 
                   Tarif Entreprise : <strong >{price + 5000} DA</strong> 
                  </Typography>
                  )
                }


            <Typography component="li" variant="subtitle1">
              Durée de la Formation:
              <strong>5 jours étalés sur 5 semaines</strong>
            </Typography>
            <Typography component="li" variant="subtitle1">
              Un Forum de <strong>Questions/Réponses à disposition</strong>
            </Typography>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Info;
