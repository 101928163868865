import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  bgGrey: {
    background: theme.palette.common.offWhite,
  },
}));

function Description({ title, description }) {
  const classes = useStyle();
  return (
    <section className={`section ${classes.bgGrey}`}>
      <div className="section-center">
        <Typography component="h2" variant="h4">
          {title}
        </Typography>
        <Typography component="h3" variant="h5">
          {description ? description : ""}
        </Typography>
      </div>
    </section>
  );
}

export default Description;
