import React from "react";
import Title from "../Title";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  backgroundGrey: {
    ...theme.section.backgroundGrey,
  },
  section: {
    ...theme.section.main,
  },
  sectionWhite: {
    ...theme.section.white,
  },
  sectionCenter: {
    ...theme.section.center,
  },
}));

function Program({ data }) {
  const classes = useStyle();
  return (
    <section
      className={`${classes.section} ${classes.sectionCenter} formation-program`}
    >
      <Title title="Le programme" />
      <div className="col-2">
        {data.map((program) => {
          return (
            <div
              key={program.id}
              className="callout_with_border"
              id="program"
              style={{ borderColor: program.color }}
            >
              <Typography
                component="h3"
                variant="h6"
                className="callout__heading"
              >
                {program.title}
              </Typography>
              <Typography component="p" variant="body1">
                {program.content.content}
              </Typography>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Program;
