import React from "react";
import { Link, useStaticQuery } from "gatsby";
import { Typography, Button } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FaCalculator } from "react-icons/fa";

const query = graphql`
  {
    file(relativePath: { eq: "hero-img1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const useStyle = makeStyles((theme) => ({
  root: {
    height: "80vh",
  },
  sectionCenter: {
    ...theme.section.center,
  },
  btn: {
    marginTop: "2rem",
    fontSize: "1.5rem",
    padding: "10px 30px",
    borderRadius: "50px",
  },
  flexCenter: {
    ...theme.section.flexCenter,
  },
  icone: {
    width: "60px",
    marginBottom: "30px",
  },
  title: {
    color: theme.palette.common.offWhite,
    fontWeight: 700,
  },
  subTitle: {
    color: theme.palette.common.grayLight,
  },
}));

function Hero2({
  title,
  description,
  link,
  cta,
  backgroundColor,
  secondary,
  icone,
}) {
  const classes = useStyle();
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query);

  const theme = useTheme();
  let bgc = backgroundColor ? backgroundColor : theme.palette.secondary.main;

  return (
    <div className={classes.root} style={{ backgroundColor: bgc }}>
      <div className={classes.sectionCenter}>
        <article>
          <div className={classes.flexCenter}>
            {icone ? (
              <img src={icone} className={classes.icone} alt={title} />
            ) : null}

            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              className={classes.subTitle}
            >
              {description}
            </Typography>

            {cta ? (
              <Button
                component={Link}
                color="primary"
                variant="contained"
                to={link}
                className={classes.btn}
              >
                {cta}
              </Button>
            ) : null}
          </div>
        </article>
      </div>
    </div>
  );
}

export default Hero2;
